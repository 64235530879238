import { Map, tileLayer, layerGroup, GridLayer, Util, DomUtil, extend, Control, control, LatLngBounds, Point } from 'leaflet'
import { closemap } from '../control/Control.CloseMap'
import { pgpoint } from '../layer'
import { global } from '../constants/global'
import { PGRoute } from '../routing/PGRoute'
import '../styles/pgroute.css'

if (window?.chrome !== undefined) {
  const originalInitTile = GridLayer.prototype._initTile

  GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile)

      const tileSize = this.getTileSize()

      tile.style.width = `${tileSize.x + 1}px`
      tile.style.height = `${tileSize.y + 1}px`
    }
  })
}

const streetsLayerURL = 'https://tiles.tcol.it/WM/256/WST/{z}/{x}/{z}_{x}_{y}.webp'
const DEFAULT_TRAFFIC_LAYER_URL = 'https://api.tomtom.com/traffic/map/4/tile/flow/relative/{z}/{x}/{y}.png'
const DEFAULT_SATELLITE_LAYER_URL = 'https://api.tomtom.com/map/1/tile/sat/main/{z}/{x}/{y}.jpg'

//const streetsLayerURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

/*
 * @class PGMappy
 * @aka IOLMap.PGMappy
 * @inherits Map
 *
 * The central class of the API — it is used to create a map on a page and manipulate it.
 *
 * @example
 *
 * ```js
 * // initialize the map on the "map" div with a given center and zoom and two base layer street a satellite
 * let map = new IOLMap.PGMappy('map', {  oppure let map = new PGMappy('map', {
 * 	lat: 45.505,
 *  lon: 9.13
 * 	zoom: 13
 * });
 * ```
 *
 */
export const PGMappy = Map.extend({
  options: {
    // @option nameContainer
    nameContainer: undefined,
    // @option latitude
    lat: undefined,
    // @option longitute
    lon: undefined,
    // @option zoom INT default 5
    z: 5,
    // @option zoom INT default 5
    minZoom: 5,
    // @option wheelZoomEnabled INT default 1
    wheelZoomEnabled: 1,
    // @option lng INT lingua, default 1
    lng: 1,
    // @option mapWidth
    mapWidth: undefined,
    // @option mapHeight
    mapHeight: undefined,
    // @option defaultLevelControl
    defaultLevelControl: false,
    // @option defaultSelectedLayers ["Mappa", "Satellite", "Traffico"];
    defaultSelectedLayers: ['Mappa'],
    // @option trafficLayerUrl;
    trafficLayerUrl: DEFAULT_TRAFFIC_LAYER_URL,
    // @option satelliteLayerUrl;
    satelliteLayerUrl: DEFAULT_SATELLITE_LAYER_URL,
    // @option trafficApiKey;
    trafficApiKey: '',
    // @option satelliteApiKey;
    satelliteApiKey: '',
    // @option offsetX
    offsetRight: 0,
    // @option offsetY
    offsetTop: 0,
    // @option mapType INT Tipo di mappa da visualizzare (default PGMAP_TYPE_MAP).
    // I valori possibili sono: PGMAP_TYPE_MAP (modalità mappa semplice), PGMAP_TYPE_ORTO (modalità ortofoto)
    mapType: global.PGMAP_TYPE_MAP,
    // @option sito String
    sito: undefined,
    // @option closableMap: Boolean = true
    // If true add a close button to the map
    closableMap: true,
    // @option onCloseCallback: function = undefined
    // Callback invoked when the close button is clicked/touched
    onCloseCallback: undefined,
    // @option PGPoints: Array = []
    // Markers to add on map initialization
    PGPoints: undefined
  },

  initialize: function (options) {
    // set current options
    Util.setOptions(this, options)

    // call parent constructor
    Map.prototype.initialize.call(this, options.nameContainer, options)

    // Remove default zoom control
    this.zoomControl.remove()

    // Add new zoom control at 'bottomright'
    this.customZoomControl = control
      .zoom({
        position: 'bottomright'
      })
      .addTo(this)

    // add trademark
    this._addTrademark()

    // add layers
    this.baseLayers = {}

    // add PGPoints array
    this.PGPoints = []

    // Aggiungo le funzionalita di routing
    this.pgRoute = null
    this.routePathLayerGroup = null
    this.routePointsLayerGroup = null

    //this._addCloseControl()

    this.italyBounds = [
      [35.0763, 6.602696], // Sud-Ovest (lat, lng)
      [47.10169, 19.12499] // Nord-Est (lat, lng)
    ]
    this._addInitialLayers(options)

    // Imposta la dimensione della mappa
    if (options.mapWidth && options.mapHeight) {
      this.getContainer().style.width = options.mapWidth
      this.getContainer().style.height = options.mapHeight
    }

    // Disabilita lo zoom con il mouse se passato come parametro 0
    if (options.wheelZoomEnabled === 0) {
      this.scrollWheelZoom.disable()
    }

    // Aggiunge i PGPoint se ve ne sono e centra la mappa sul loro baricentro se non è stato passato
    // lat lon iniziale
    if (this.options.PGPoints) {
      this._addPGPoints()
      if (!this.options.lat || !this.options.lon) {
        this.getRouteCenter(this.options.PGPoints)
      } else {
        this.setCenterAndZoom(this.options.lon, this.options.lat, this.options.z)
      }
    } else if (this.options.lat && this.options.lon) {
      this.setCenterAndZoom(this.options.lon, this.options.lat, this.options.z)
    }
  },

  _addCloseControl: function () {
    if (this.options.closableMap) {
      this.options.onCloseCallback =
        this.options.onCloseCallback ||
        function (e) {
          console.log('Close map handler')
        }

      closemap({ closeCallback: this.options.onCloseCallback }).addTo(this)
    }
  },

  _addInitialLayers: function () {
    this.baseLayers.streets = tileLayer(streetsLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-streets'
    })
    if (this.options.mapType === global.PGMAP_TYPE_MAP) {
      this.baseLayers.streets.addTo(this)
    }

    // Layer foto satellitari
    if (this.options.satelliteLayerUrl === DEFAULT_SATELLITE_LAYER_URL) {
      // Se è quello di default, aggiungi la chiave API
      this.satelliteLayerURL = `${DEFAULT_SATELLITE_LAYER_URL}?key=${this.options.satelliteLayerUrl}`
    } else {
      // Altrimenti, usa l'URL personalizzato
      this.satelliteLayerURL = this.options.satelliteLayerUrl
    }

    this.baseLayers.satellite = tileLayer(this.satelliteLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-satellite',
      noWrap: true,
      tileSize: 256
    })

    this.baseLayers.satellite.createTile = (coords, done) => this.createTile(coords, 9, done, this.satelliteLayerURL)

    if (this.options.mapType === global.PGMAP_TYPE_ORTO) {
      this.baseLayers.satellite.addTo(this)
    }

    // Layer del traffico
    if (this.options.trafficLayerUrl === DEFAULT_TRAFFIC_LAYER_URL) {
      // Se è quello di default, aggiungi la chiave API e il parametro thickness
      this.trafficLayerURL = `${DEFAULT_TRAFFIC_LAYER_URL}?thickness=2&key=${this.options.trafficApiKey}`
    } else {
      // Altrimenti, usa l'URL personalizzato
      this.trafficLayerURL = this.options.trafficLayerUrl
    }

    this.baseLayers.traffic = tileLayer(this.trafficLayerURL, {
      minZoom: this.options.minZoom,
      maxZoom: 18,
      id: 'iol-traffic',
      noWrap: true,
      tileSize: 256
    })
    this.baseLayers.traffic.createTile = (coords, done) => this.createTile(coords, 0, done, this.trafficLayerURL)

    // Creare il layer group "Mappa + Traffico"
    var mapAndTrafficGroup = layerGroup([this.baseLayers.streets, this.baseLayers.traffic])
    if (this.options.mapType === global.PGMAP_TYPE_TRAFFIC) {
      mapAndTrafficGroup.addTo(this)
    }

    // Aggiungi il controllo per lo switch tra i layer
    var selectedLayers = this.options.defaultSelectedLayers
    this.layersControl = control.layers(Object.assign(selectedLayers.includes('Mappa') && { Mappa: this.baseLayers.streets }, selectedLayers.includes('Satellite') && { Satellite: this.baseLayers.satellite }, selectedLayers.includes('Traffico') && { Traffico: mapAndTrafficGroup }), null, {
      position: 'bottomleft'
    })

    this.on('baselayerchange', () => {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
    })

    // Aggiunge il controllo allo switch
    if (this.options.defaultLevelControl) {
      this.layersControl.addTo(this)
    }

    // Permette lo zoom massimo solo su certi bound di alcune citta principali
    this.on('zoom', (event) => {
      const targetZoom = this.getZoom()
      if (targetZoom < 18) return

      const center = this.getCenter()
      // Verifica se il centro è all'interno di almeno uno dei bounds
      if (!global.boundsArrayZ18.some((bound) => new LatLngBounds(bound.southWest, bound.northEast).contains(center))) {
        this.setZoom(17, { animate: false })
        this.fire('zoomend')
      }
    })
  },

  _addPGPoints: function () {
    if (this.options.PGPoints.length > 0) {
      this.options.PGPoints.forEach((m) => {
        this.pointAdder(pgpoint(m))
      })
    }
  },

  _addTrademark: function () {
    const attributionControl = this.attributionControl
    this.removeControl(attributionControl)
    const CustomControl = Control.extend({
      options: {
        position: 'bottomright'
      },
      onAdd: function () {
        const container = DomUtil.create('div', 'my-custom-control')
        const currentYear = new Date().getFullYear()
        //container.innerHTML = `© ${currentYear} HERE | Tuttocittà`
        //container.innerHTML = `<a href="https://leafletjs.com/">Leaflet</a> | © ${currentYear}<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | © ${currentYear} TomTom | Tuttocittà`
        container.innerHTML = `© ${currentYear} HERE | © ${currentYear} TomTom | Tuttocittà`
        container.style.bottom = '-75px'
        container.style.marginRight = '50px'
        container.style.background = 'rgba(255, 255, 255, 0.7)'
        container.style.padding = '5px'
        container.style.borderRadius = '5px'
        return container
      }
    })
    this.addControl(new CustomControl())
  },

  isTileInItaly: function (coords, start_zoom) {
    const { x, y, z } = coords
    // Se il livello di zoom è inferiore a start_zoom, accetta il tile
    if (z < start_zoom) return true

    // Calcola le coordinate geografiche degli angoli del tile
    const corners = [
      // Nord-Ovest
      [(Math.atan(Math.sinh(Math.PI * (1 - (2 * y) / Math.pow(2, z)))) * 180) / Math.PI, (x / Math.pow(2, z)) * 360 - 180],
      // Nord-Est
      [(Math.atan(Math.sinh(Math.PI * (1 - (2 * y) / Math.pow(2, z)))) * 180) / Math.PI, ((x + 1) / Math.pow(2, z)) * 360 - 180],
      // Sud-Ovest
      [(Math.atan(Math.sinh(Math.PI * (1 - (2 * (y + 1)) / Math.pow(2, z)))) * 180) / Math.PI, (x / Math.pow(2, z)) * 360 - 180],
      // Sud-Est
      [(Math.atan(Math.sinh(Math.PI * (1 - (2 * (y + 1)) / Math.pow(2, z)))) * 180) / Math.PI, ((x + 1) / Math.pow(2, z)) * 360 - 180]
    ]

    // Verifica se almeno uno degli angoli è dentro i confini dell'Italia
    return corners.some((corner) => corner[0] >= this.italyBounds[0][0] && corner[0] <= this.italyBounds[1][0] && corner[1] >= this.italyBounds[0][1] && corner[1] <= this.italyBounds[1][1])
  },

  // Definisci la funzione createTile come metodo della classe/oggetto
  createTile: function (coords, from_zoom = 0, done, layerUrl) {
    const tileUrl = Util.template(layerUrl, extend({}, this.options, coords)) // Genera l'URL del tile

    // Controlla se il tile è in Italia
    if (this.isTileInItaly(coords, from_zoom)) {
      const tile = DomUtil.create('img')
      tile.onload = () => done(null, tile)
      tile.onerror = () => done(null, null)
      tile.src = tileUrl
      return tile
    } else {
      const tile = DomUtil.create('div')
      tile.style.display = 'none'
      done(null, tile)
      return tile
    }
  },

  // Aggiunge un PGPoint alla mappa
  pointAdder: function (pgpoint) {
    pgpoint.addTo(this)
    this.PGPoints.push(pgpoint)
  },

  // Nasconde il control level
  levelControlHide: function () {
    if (this.layersControl) this.layersControl.getContainer().style.display = 'none'
  },

  // Mostra il control level
  levelControlShow: function () {
    if (this.layersControl) this.layersControl.getContainer().style.display = 'block'
  },

  // Cambia visualizzazione della mappa
  toggleLayer: function () {
    if (this.hasLayer(this.baseLayers.streets)) {
      this.removeLayer(this.baseLayers.streets)
      this.baseLayers.satellite.addTo(this)
    } else if (this.hasLayer(this.baseLayers.satellite)) {
      this.removeLayer(this.baseLayers.satellite)
      this.baseLayers.streets.addTo(this)
    }
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  toggleLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  addLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      return
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // toggle traffic layer realtime
  removeLayerTraffic: function () {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    }
  },

  // toggle traffic layer in specific day and hour
  toggleLayerTrafficData: function (gg, hh) {
    if (this.hasLayer(this.baseLayers.traffic)) {
      this.removeLayer(this.baseLayers.traffic)
    } else {
      this.baseLayers.traffic.setUrl(this.trafficLayerURL + '&date=' + gg + '%3B' + hh)
      this.baseLayers.traffic.addTo(this)
    }
  },

  // Nasconde il control zoom
  customZoomControlHide: function () {
    this.customZoomControl.getContainer().style.display = 'none'
    const element = document.querySelector('.my-custom-control')
    element.style.bottom = '-13px'
  },

  // Mostra il control zoom
  customZoomControlShow: function () {
    this.customZoomControl.getContainer().style.display = 'block'
    const element = document.querySelector('.my-custom-control')
    element.style.bottom = '-88px'
  },

  // Rimuove uno specifico PGPoint dalla mappa
  removePGPoint: function (pgpoint) {
    pgpoint.remove()
  },

  // Rimuove tutti i PGPoint dalla mappa (non quelli del percorso)
  removePGPoints: function () {
    this.PGPoints.forEach((pgpoint) => {
      pgpoint.remove()
    })
  },

  // Centra la mappa in un punto con un dato zoom
  setCenterAndZoom: function (lon, lat, zoom) {
    const centerPoint = this.project([lat, lon], zoom)

    const offsetPoint = new Point(centerPoint.x - this.options.offsetRight / 2, centerPoint.y - this.options.offsetTop / 2)

    const newCenter = this.unproject(offsetPoint, zoom)

    this.setView(newCenter, zoom)
  },

  // Rettifica il bound sulla base degli offset della mappa offsetRight e offsetTop
  adjustedFitBounds: function (bounds) {
    const offsetRight = -2 * this.options.offsetRight
    const offsetTop = 2 * this.options.offsetTop
    const currentNorthLat = bounds.getNorth()
    const currentSouthLat = bounds.getSouth()
    const currentWestLng = bounds.getWest()
    const currentEastLng = bounds.getEast()

    const pixelHeight = this.getSize().y
    const pixelWidth = this.getSize().x

    const latOffset = (currentNorthLat - currentSouthLat) * (offsetTop / pixelHeight)
    const lngOffset = (currentEastLng - currentWestLng) * (offsetRight / pixelWidth)

    const newNorthLat = currentNorthLat + latOffset
    const newSouthLat = currentSouthLat - latOffset
    const newWestLng = currentWestLng + lngOffset
    const newEastLng = currentEastLng

    const adjustedBounds = new LatLngBounds([newSouthLat, newWestLng], [newNorthLat, newEastLng])
    return adjustedBounds
  },

  // Centra la mappa sul baricentro di un array di PGPoints con il corretto livello di zoom
  getRouteCenter: function (pgpoints) {
    const arrayOfLatLngs = pgpoints.map(function (item) {
      return { lat: item.lat, lon: item.lon }
    })
    const bounds = new LatLngBounds(arrayOfLatLngs)
    let adjustedFitBounds = map.adjustedFitBounds(bounds)
    this.fitBounds(adjustedFitBounds)
  },

  // Metodo per calcolare un percorso
  getRoute(routeParams, callback, fromMovePin = false) {
    if (fromMovePin) this.removeRoutePath()
    else this.removeRoute()

    this.pgRoute = new PGRoute(routeParams, callback)

    return this.pgRoute.getRoute(this).then(() => {
      this.addRouteText()
    })
  },

  // Metodo che disegna tutti i percorsi del route
  drawRoute(routeParams, route) {
    this.pgRoute = new PGRoute(routeParams)
    this.pgRoute.drawRoute(route, this)
  },

  // Metodo che disegna un percorso
  addRoute(routeList) {
    this.pgRoute.addRoute(routeList, this)
  },

  // Metodo che aggiunge gli eventi ad un percorso
  addEventRoute(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    this.pgRoute.addEventRoute(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'), (selRouteClass = 'suggested-path-selected'))
  },

  // Metodo che aggiunge gli eventi alla testata di un percorso
  addEventRouteHeader(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    this.pgRoute.addEventRouteHeader(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'), (selRouteClass = 'suggested-path-selected'))
  },

  // Metodo che aggiunge gli eventi al dettaglio di un percorso
  addEventRouteDetails(selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]') {
    this.pgRoute.addEventRouteDetails(this, (selHeaderRoute = '[data-alt]'), (selDetailRoute = '[data-lat][data-lon]'))
  },

  // Metodo che inserisce il percorso testuale all'interno dell'elemento html id
  addRouteText() {
    this.pgRoute.addRouteText(this)
  },

  // Metodo che rimuove il percorso (path e points)
  removeRoute() {
    if (this.routePathLayerGroup) {
      this.removeRoutePath()
      this.removeLayer(this.routePointsLayerGroup)
      this.routePointsLayerGroup = null
    }
  },

  // Metodo che rimuove il path del percorso e rende null pgRoute
  removeRoutePath() {
    this.cleanRoute()
    this.pgRoute = null
  },

  // Metodo che rimuove il path del percorso
  cleanRoute() {
    if (this.routePathLayerGroup) {
      this.removeLayer(this.routePathLayerGroup)
      this.routePathLayerGroup = null
      if (this.pgRoute.routeParams.textOpts) {
        this.pgRoute.textEl.innerHTML = '&nbsp;'
      }
    }
    if (this.pgRoute) {
      this.pgRoute.polylines = []
      this.pgRoute.route = null
    }
  }
})

// PGMappy disponibile a livello globale per compatibilita vecchie API
window.PGMappy = PGMappy
